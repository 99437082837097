<template>
  <div>
    <nav-header></nav-header>
    <div class="control">
      <div class="envCon" :class="lang">
        <div class="header-nav-title feature">
          <h4>{{$t('control.controltitle')}}</h4>
          <div v-if="this.$i18n.locale =='en'">
           <p>SOHO China is committed to cooperating with partners to jointly drive ESG management from the three aspects of environmental protection, social responsibility and corporate governance. In November 2021, SOHO China established an ESG Governance Committee responsible for reporting to the board of directors in terms of ESG matters, and overseeing the establishment and implementation of the vision, strategies, goals, and policies of the company's environmental, social and corporate governance. The ESG committee directly leads the ESG working group, improves internal management mechanisms, clarifies supervisory responsibilities and work objectives, integrates sustainable development into the company's daily management, provides guiding methods for the implementation of ESG work, and strictly regulates and effectively manages ESG affairs .</p>
          </div>
          <p v-if="this.$i18n.locale=='zh'">
            SOHO中国致力于与合作伙伴团结协作，从环境保护、社会责任、企业管治三方面共同推动ESG管理工作。2021年 11月，SOHO中国在董事会设立ESG委员会，負責就ESG事宜向董事会汇报，并监察本公司环境、社会及管治愿景、策略、目标及政策的制定和实施。ESG委员会直接领导ESG工作组，完善内部管理机制，明确监管责任和工作目标，将可持续发展融入本公司的日常管理中，为ESG工作落地提供指导性办法，严格规范和有效管理ESG各项事务。
          </p>
        </div>
<!--        <div class="dangerSystem feature">-->
<!--          <div  v-if="this.$i18n.locale=='zh'">-->
<!--            <h4>ESG风险管理体系</h4>-->
<!--            <ul>-->
<!--              <li>环境管理</li>-->
<!--              <li>职业健康</li>-->
<!--              <li>反贪污</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div  v-else>-->
<!--            <h4>ESG Risk Management System-->
<!--            </h4>-->
<!--            <ul>-->
<!--              <li>Environment Management</li>-->
<!--              <li>Occupational Health</li>-->
<!--              <li>Anti-Corruption</li>-->
<!--            </ul>-->
<!--          </div>-->

<!--        </div>-->
        <div class="header-title feature">
            <h4>{{$t('control.controltitle1')}}</h4>
        </div>
        <div class="ori-img feature" v-if="this.$i18n.locale=='zh'">
            <img src="/img/control.png" alt="">
        </div>
        <div class="ori-img feature" v-if="this.$i18n.locale=='en'">
           <img src="/img/control/encontrols.png" alt="">
        </div>
        <div class="header-title feature">
            <h4>{{$t('control.risk')}}</h4>
        </div>
        <div class="feature risk-management-pc" v-if="this.$i18n.locale=='zh'">
            <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/zp_20230112113245.png" alt="" style="width:100%;height:auto;">
        </div>
        <div class="feature risk-management-pc" v-if="this.$i18n.locale=='en'">
            <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/ep20230111171039.png" alt="" style="width:100%;height:auto;">
        </div>
      
        <div class="feature risk-management-mobile" v-if="this.$i18n.locale=='zh'">
            <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/zy_20230112113306.png" alt="" style="width:100%;height:auto;">
        </div>
        <div class="feature risk-management-mobile" v-if="this.$i18n.locale=='en'">
            <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/ey20230111171019.png" alt="" style="width:100%;height:auto;">
        </div>
        <div class="policy feature" v-if="this.$i18n.locale=='zh'">
          <h4>{{$t('control.esgpolicy')}}</h4>
          <ul>

            <a href="/pdf/ESGCommittee.PDF" target="_blank"> <li>ESG委员会职责范围书</li></a>
            <a href="/pdf/env.pdf" target="_blank"> <li>SOHO中国环境管理政策</li></a>
            <a href="/pdf/greenHealthy.pdf" target="_blank"> <li>SOHO中国绿色健康建筑政策</li></a>
            <a href="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/Biodiversity.pdf" target="_blank"> <li>SOHO中国生物多样性保护政策</li></a>
            <a href="/pdf/Employment.pdf" target="_blank"> <li>SOHO中国雇佣与劳动准则管理政策</li></a>
            <a href="/pdf/diversifiedPolicy.pdf" target="_blank"> <li>  SOHO中国董事会多元化政策</li></a>
             <a href="/pdf/performance.pdf" target="_blank"> <li> SOHO中国绩效薪酬政策及绩效薪酬追索扣回政策</li></a>
            <a href="/pdf/GreenProcurement.pdf" target="_blank"> <li>SOHO中国绿色采购及可持续供应链管理政策</li></a>
            <a href="/pdf/greenLease.pdf" target="_blank"> <li>SOHO中国绿色租赁政策</li></a>
            <a href="/pdf/FairHospitality.pdf" target="_blank"> <li>SOHO中国公平待客及营销政策</li></a>
            <a href="/pdf/Integrity.pdf" target="_blank"> <li>SOHO中国廉洁准则及职业道德管理政策</li></a>
            <a href="/pdf/complaint.pdf" target="_blank"> <li>SOHO中国投诉举报处理政策</li></a>
            <a href="/pdf/Investment.pdf" target="_blank"> <li>SOHO中国社区投资管理政策</li></a>
            <a href="/pdf/Climate.pdf" target="_blank"> <li>SOHO中国应对气候变化管理政策</li></a>
          </ul>
        </div>
        <div class="policy feature" v-if="this.$i18n.locale=='en'">
          <h4>{{$t('control.esgpolicy')}}</h4>
          <ul>
            <a href="/pdf/enESGCommittee.PDF" target="_blank"> <li>Description of ESG Committee</li></a>
            <a href="/pdf/enenv.pdf" target="_blank"> <li>Environment Management Policy</li></a>
            <a href="/pdf/engreenHealthy.pdf" target="_blank"> <li>Green and Healthy Building Policy</li></a>
            <a href="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/enBiodiversity.pdf" target="_blank"> <li>Biodiversity Protection Policy</li></a>
            <a href="/pdf/enEmployment.pdf" target="_blank"> <li>Management Policy for Employment and Labor Standards</li></a>
            <a href="/pdf/endiversifiedPolicy.pdf" target="_blank"> <li> Board of Directors (BOD) Diversified Policy</li></a>
            <a href="/pdf/Policies for Performance-Based Remuneration and its Clawback.pdf" target="_blank"> <li>Policies for Performance-Based Remuneration and its Clawback</li></a>
            <a href="/pdf/enGreenProcurement.pdf" target="_blank"> <li>Green Procurement and Sustainable Supply Chain Policy </li></a>
            <a href="/pdf/engreenLease.pdf" target="_blank"> <li>Green Lease Policy</li></a>

            <a href="/pdf/enFairHospitality.pdf" target="_blank"><li>Fair Hospitality and Marketing Management Policy</li></a>
            <a href="/pdf/enIntegrity.pdf" target="_blank"> <li>Integrity Standards and Business Ethics Management Policy</li></a>
            <a href="/pdf/encomplaint.pdf" target="_blank"> <li>Complaint Report Handling Policy</li></a>
            <a href="/pdf/enInvestment.pdf" target="_blank"> <li>Community Investment Management Policy</li></a>
            <a href="/pdf/enClimate.pdf" target="_blank"> <li>Management Policy to Address Climate Changes</li></a>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NavHeader from "../components/Header"
import {mapState} from  "vuex"
export default {
  name: "Control",
  components:{
    NavHeader
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });

  },
  computed:{
    ...mapState(
        {
          lang:state =>state.lang
        }
    )

  },
}
</script>

<style scoped lang="scss">
p{
  text-align: justify;
}
.en p{

  font-family: "Barlow-Regular";
}
.control{
  background-color: #F5F5F7;
  padding-bottom: 100px;
  margin-top: 0px;
}
h4{
  font-size: 64px;
  color:rgb(34, 195, 173);
  font-weight: bold;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 0px;
}
.header-nav-title h4{
  padding-bottom: 70px;
}
.en{
  .dangerSystem{
    ul{
      li{
        font-family: "Barlow-Regular";
      }
    }
  }
}
.dangerSystem{
  padding: 0 70px;
  h4{
    padding-bottom: 70px;
  }
  ul{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li{
      width: 30%;
      background: #ffffff;
      color: #5EC0AE;
      height: 90px;
      line-height: 90px;
      text-align: center;
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 600;
    }
    li:hover{
      cursor: pointer;
    }
  }
}

.policy h4{
  padding-top: 60px;
  padding-bottom: 60px;
}
.en h4{
  font-family: "BarlowCondensed-bold";
}
.control p{
  font-size: 22px;
  line-height:1.6;
  color: #353535;
  padding:0 70px;
}
.control .en p{
  font-size: 18px;
}
.en .header-nav-title p{
  line-height: 30px;
}
.header-nav-title p{
  text-align: justify;
  font-size: 24px;
  line-height: 47px;
  letter-spacing: 1px;
  color: #353535;
}
.header-title p{
  font-size: 36px;
}
.en .header-title p{
  font-size: 24px;
  font-family: "Barlow-Regular";
}
.header-title{
  text-align: center;
  padding: 0 70px;
}
.header-title p{
  text-align: center;
}
.connect {
  text-align: center;
  padding-top: -10px;
  p{
    text-align: center;
  }
}
.en .connect{
  text-align: center;
  padding-top: -10px;
  p{
    text-align: center;
    font-family: "Barlow-Regular";
    line-height: 34px;
  }
}
.control img{
  margin: 70px auto 0;
  display: block;
  width: 949px;
  height: 486px;
}
.control .juzhen img{
  height: 700px;
}
.policy {
  width:100%;
  padding: 0 70px;
  box-sizing: border-box;
}
.policy li{
  width: 100%;
  height: 111px;
  line-height: 111px;
  text-align: center;
  font-size: 24px;
  background: #ffffff;
  margin-top: 20px;
  border-radius: 10px;
}
.policy a{
  color: #545454;
}
.policy ul li:hover{
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 5px 12px 0 rgba(0,0,0,.2);
  transform: translate3d(0,-2px,0);
  transition: all .2s linear;
  color: #2EC4B6;
  font-weight: bold;
}
.risk-management-mobile{
  display: none;
}
@media (max-width: 960px) {
  .control{
    padding-bottom: 50px;

  }
  .en .dangerSystem{
    ul{
      li{
        box-sizing: border-box;
        padding-top: 16px;
        height: 60px;
        font-size: 12px;
        line-height: 1;
      }
    }
  }
  .dangerSystem{
    padding: 0 10px;
    ul{
      li{
        box-sizing: border-box;
        padding-top: 0px;
        height: 60px;
        font-size: 12px;
        line-height: 60px;
      }
    }
  }
  h4{
    font-size: 28px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 23px;
  }
  .header-nav-title h4{
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .policy h4{
    padding-top:40px;
    padding-bottom: 40px;
  }

  .control p{
    font-size: 14px;
    line-height: 2;
    color: #353535;
    padding:0 10px;
    text-align: left;
  }
  .control .en p{
    font-size: 14px;
    text-align:left;
  }
  .en .connect p{
    text-align: left;
    line-height: 24px;
  }

  .header-title p{
    font-size: 14px;
  }
  .header-title{
    text-align: center;
  }
  .header-title p{
    text-align: center;
  }
  .connect {
    text-align: left;
  }

  .control img{
    margin: 20px auto 0;
    display: block;
    width:100%;
    height: auto;
  }
  .control .juzhen img{
    width: 100%;
    display: block;
    height: auto;
  }
  .policy {
    width:100%;
    padding: 0px;
    box-sizing: border-box;
  }
  .policy li{
    width: 96%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .en .policy li{
    font-size: 12px;
  }
  .risk-management-mobile{
  display: block;
  padding: 0 10px;
  }
  .risk-management-pc{
  display: none;
}
}
</style>
